<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.group.collection.title'),
        url: 'collections'
      },
      {
        name: $t('studio.group.collection.discount_title')
      }
    ]"
    :title="$t('studio.group.collection.discount_title')"
  >
    <s-button variant="primary" size="md" class="!min-w-[11.6rem]" @click="onClickCreate">
      {{ $t('studio.group.collection.discount_registration_btn') }}
    </s-button>
  </st-title>

  <div v-show="!isLoading">
    <s-text as="h3" role="title6" class="text-on-surface-elevation-1 !font-bold">
      {{ productName || '' }}
    </s-text>
    <st-box v-if="noRegisterDiscounts" class="mt-24">
      <div class="flex gap-12 items-center mb-20">
        <radio-buttons
          v-model="discountType"
          containerClass="flex gap-8"
          name="discountType"
          :options="options"
          @update:modelValue="onDiscountTypeChange"
        />
      </div>
      <st-table
        :data="discountList"
        :columns="columns"
        :paging="{ total, pageSize, show: true }"
        @onRowClick="onClickDiscount"
        @pageChange="onPageChange"
      >
        <template #col-0="{ row }">
          <st-td aLeft paddingX16>
            <s-text
              as="span"
              role="text3"
              :class="{ 'text-brand-primary': row.discountStatus === DISCOUNT_STATUS.IN_PROGRESS }"
            >
              {{ discountStatus(row.discountStatus) }}
            </s-text>
          </st-td>
        </template>
        <template #col-5="{ row }">
          <st-td onlyButton>
            <s-button
              v-if="row.discountStatus === DISCOUNT_STATUS.IN_PROGRESS"
              variant="outline"
              size="sm"
              @click="(e: Event) => suspendDiscount(e, row.discountNo)"
            >
              {{ t('studio.prj_prod.this_prod.prod_mgmt_discount_list_suspend_btn') }}
            </s-button>
            <s-button
              v-else
              variant="outline"
              size="sm"
              :disabled="row.discountStatus === DISCOUNT_STATUS.FINISHED"
              @click="(e: Event) => deleteDiscount(e, row.discountNo)"
            >
              {{ t('studio.prj_prod.this_prod.prod_mgmt_discount_list_delete_btn') }}
            </s-button>
          </st-td>
        </template>
        <template #empty>
          <tr>
            <st-td colspan="6">
              <div class="flex justify-center items-center mt-8 min-h-[35rem]">
                <p class="text-md leading-lg text-on-surface-elevation-2 text-center">
                  {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_no_data') }}
                </p>
              </div>
            </st-td>
          </tr>
        </template>
      </st-table>
    </st-box>
    <st-box v-else class="mt-24">
      <div class="py-20 flex flex-col gap-20 items-center justify-center">
        <img src="@/assets/images/discount/20-2-2-img.svg" alt="" class="block" />
        <p class="text-3xl font-bold leading-2xl text-on-surface-elevation-1 text-center">
          <safe-html :html="$t('studio.group.collection.discount_main_guide')" />
        </p>
      </div>
    </st-box>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchCollectionDetailsApi } from '@/apis/collection.api';
import { deleteProductDiscountApi, fetchProductDiscountListApi } from '@/apis/product-discount.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import SuspendDiscountDialog from '@/components/product-discount/suspend-discount-dialog.vue';
import RadioButtons from '@/components/validation/radio-buttons.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { MODE, SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { DISCOUNT_TYPE } from '@/constants/discount.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
// import { PLAN_STATUS } from '@/constants/products.const';
import { DISCOUNT_STATUS } from '@/constants/products-discount.const';
import { COLLECTION_URL } from '@/constants/url.const';
import type { CollectionInfos } from '@/types/collection/collection.type';
import type { CollectionDetailResponse } from '@/types/collection/collection-response.type';
import type { FormOption } from '@/types/common/form.type';
import type { ProductDiscountListRequestModel } from '@/types/product-discount/product-discount-request.type';
import type {
  ProductDiscountListItem,
  ProductDiscountListResponseModel
} from '@/types/product-discount/product-discount-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { formatDateTime } from '@/utils/date.util';

const route = useRoute();
const { t, locale } = useI18n();

const { checkMenuWritable } = useApp();

const productId = Number(route.params.collectionId);
const collectionInfo = ref<CollectionDetailResponse>();
const isLoading = ref<boolean>(true);
const discountType = ref<string>(DISCOUNT_TYPE.ALL);
const discountList = ref<ProductDiscountListItem[]>([]);
const total = ref<number>(0);
const currentPage = ref<number>(1);
const pageSize = 10;

// If product has discount, show discount list
const noRegisterDiscounts = ref<boolean>(false);

const options = ref<FormOption[]>([
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_all_msg'),
    value: DISCOUNT_TYPE.ALL
  },
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_active_or_upcoming_msg'),
    value: DISCOUNT_TYPE.APPLYING
  },
  {
    label: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_filter_expired_msg'),
    value: DISCOUNT_TYPE.END
  }
]);

const REQUEST_STATUS = {
  [DISCOUNT_TYPE.ALL]: `${DISCOUNT_STATUS.SCHEDULED},${DISCOUNT_STATUS.IN_PROGRESS},${DISCOUNT_STATUS.FINISHED}`,
  [DISCOUNT_TYPE.APPLYING]: `${DISCOUNT_STATUS.SCHEDULED},${DISCOUNT_STATUS.IN_PROGRESS}`,
  [DISCOUNT_TYPE.END]: `${DISCOUNT_STATUS.FINISHED}`
};

const REQUEST_SORT = {
  CREATED_AT: 'CREATED_AT',
  STATUS: 'STATUS',
  DISCOUNT_NAME: 'DISCOUNT_NAME',
  DISCOUNT_RATE: 'DISCOUNT_RATE',
  REMAIN_COUNT: 'REMAIN_COUNT',
  START_DATE: 'START_DATE'
};

const discountRequest = ref<ProductDiscountListRequestModel>({
  status: REQUEST_STATUS[DISCOUNT_TYPE.ALL],
  sort: REQUEST_SORT.CREATED_AT,
  direction: '',
  page: currentPage.value,
  size: pageSize
});

// const isReleasedCollection = computed(
//   () => collectionInfo.value?.planStatus === PLAN_STATUS.RELEASE
// );
const productName = computed(
  () =>
    collectionInfo.value?.productInfo.find(
      (c: CollectionInfos) => c.languageCd === locale.value
    )?.productName || ''
);

const discountStatus = (status: string) => {
  switch (status) {
    case DISCOUNT_STATUS.SCHEDULED:
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_status_upcoming_msg');
    case DISCOUNT_STATUS.IN_PROGRESS:
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_status_active_msg');
    case DISCOUNT_STATUS.FINISHED:
      return t('studio.prj_prod.this_prod.prod_mgmt_discount_status_expired_msg');
    default:
      return '';
  }
};

const getDiscountList = async () => {
  const res = (await fetchProductDiscountListApi(
    productId,
    discountRequest.value
  )) as ProductDiscountListResponseModel;

  if (res) {
    discountList.value = res.contents;
    total.value = res.totalElements;
  }
};

const onDiscountTypeChange = async (value: string | number) => {
  discountRequest.value.status = REQUEST_STATUS[value];
  getDiscountList();
};

const onPageChange = async (page: number) => {
  discountRequest.value.page = page;
  getDiscountList();
};

const onSortChange = async (v: ColumnSorting) => {
  if (v.sortValue === SORT_VALUE.NONE) {
    discountRequest.value.sort = REQUEST_SORT.CREATED_AT;
    discountRequest.value.direction = SORT_DIRECTION.DESC;
  } else {
    discountRequest.value.sort = v.sortKey;
    discountRequest.value.direction =
      v.sortValue === SORT_VALUE.DESC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
  }
  getDiscountList();
};

const columns: Column[] = [
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_status_header1'),
    dataIndex: 'status',
    width: '12rem',
    cellClass: 'px-12 py-16'
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_name_header2'),
    dataIndex: 'discountName',
    width: 'auto',
    cellClass: 'px-12 py-16',
    sortKey: REQUEST_SORT.DISCOUNT_NAME,
    onSort: onSortChange
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_discount_rate_header3'),
    dataIndex: 'defaultDiscountRate',
    width: '12rem',
    cellClass: 'px-12 py-16',
    sortKey: REQUEST_SORT.DISCOUNT_RATE,
    render: (row: ProductDiscountListItem) => `${row.defaultDiscountRate}%`,
    onSort: onSortChange,
    titleTooltip: t('studio.prj_prod.this_prod.prod_mgmt_discount_discount_rate_header3')
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_remaining_quantity_header4'),
    dataIndex: 'salesRemainCount',
    width: '16rem',
    cellClass: 'px-12 py-16',
    sortKey: REQUEST_SORT.REMAIN_COUNT,
    render: (row: ProductDiscountListItem) =>
      row.salesRemainCount === null || row.salesRemainCount === undefined
        ? '-'
        : `${formatNumberMultipleWithCommas(row.salesRemainCount)}`,
    onSort: onSortChange
  },
  {
    title: t('studio.prj_prod.this_prod.prod_mgmt_discount_period_header5'),
    dataIndex: 'period',
    width: '15.4rem',
    cellClass: 'px-12 py-16',
    sortKey: REQUEST_SORT.START_DATE,
    render: (row: ProductDiscountListItem) => {
      const startDate = formatDateTime(row.startAt, DATE_TIME_FORMAT_WITH_DOT);
      const endDate = formatDateTime(row.endAt, DATE_TIME_FORMAT_WITH_DOT);
      return `${startDate}~ ${endDate}`;
    },
    onSort: onSortChange
  },
  {
    title: '',
    dataIndex: 'action',
    cellClass: 'px-12 py-16',
    width: '8.8rem',
    sortKey: 'action'
  }
];

const showDeleteMessageAlert = (success: boolean) => {
  if (success) {
    showAlert({
      content: t('studio.common.popup_case_f_complete_del'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
  } else {
    showAlert({
      content: t(COMMON_ERROR_MESSAGE_KEY),
      severity: 'info',
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!w-full !max-w-full'
    });
  }
};

const suspendDiscount = async (e: Event, discountNo: number) => {
  e.preventDefault();
  e.stopPropagation();
  if (!(await checkMenuWritable())) {
    return;
  }
  const result = await showDialog({
    component: shallowRef(SuspendDiscountDialog),
    severity: 'info'
  });
  if (result) {
    const res = await deleteProductDiscountApi(productId, discountNo);
    // showDeleteMessageAlert(res?.success || false);

    if (res?.success) {
      getDiscountList();
    }
  }
};

const deleteDiscount = async (e: Event, discountNo: number) => {
  e.preventDefault();
  e.stopPropagation();
  if (!(await checkMenuWritable())) {
    return;
  }
  const result = await showConfirm({
    content: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_delete_pop_msg'),
    contentClasses: 'h-72 flex items-center justify-center mt-24',
    confirmLabel: t('studio.prj_prod.this_prod.prod_mgmt_discount_list_delete_pop_delete_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    confirmVariant: 'red',
    cancelVariant: 'outline'
  });
  if (result) {
    const res = await deleteProductDiscountApi(productId, discountNo);
    showDeleteMessageAlert(res?.success || false);

    if (res?.success) {
      getDiscountList();
    }
  }
};

const onClickCreate = async () => {
  // if (!isReleasedCollection.value) {
  //   showAlert({
  //     severity: 'info',
  //     content: t('studio.group.collection.discount_case_registration_impossible'),
  //     confirmLabel: t('studio.common.popup_case_cf_btn')
  //   });
  //   return;
  // }
  navigateToDiscountDetails(MODE.CREATE);
};

const onClickDiscount = async (discount: ProductDiscountListItem) => {
  if (!discount.discountNo) {
    return;
  }

  if (discount.discountStatus === DISCOUNT_STATUS.SCHEDULED) {
    navigateToDiscountDetails(MODE.EDIT, discount.discountNo);
    return;
  }

  navigateToDiscountDetails('', discount.discountNo);
};

const navigateToDiscountDetails = async (mode: string, discountNo?: number) => {
  if (mode === MODE.CREATE) {
    redirectTo(`${COLLECTION_URL}/${productId}/discount/${MODE.CREATE}`);
  } else if (mode === MODE.EDIT) {
    redirectTo(`${COLLECTION_URL}/${productId}/discount/${discountNo}?mode=${MODE.EDIT}`);
  } else {
    redirectTo(`${COLLECTION_URL}/${productId}/discount/${discountNo}`);
  }
};

const init = async () => {
  const result = await fetchCollectionDetailsApi(Number(productId));
  if (!result) {
    return;
  }
  collectionInfo.value = result;
  await getDiscountList();
  noRegisterDiscounts.value = discountList.value.length > 0;
  isLoading.value = false;
};

init();
</script>
